<template>
  <transition>
    <div class="main-banner d-flex align-items-center">
      <div class="max-w d-flex flex-column align-items-center">
        <div class="w-100">
          <div class="d-flex align-items-center justify-content-center w-100">
            <inline-svg class="logo mr-2 mr-md-3" src="/logo-circle-white.svg" />
            <div class="text">
              <h2>Effortlessly organize and share your</h2>
              <h1>ORIGINAL CHARACTERS</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup></script>

<style lang="sass" scoped>
.main-banner
  min-height: 70px
  background: #A83CFF
  width: 100%
  padding: 26px 18px
  color: white
  @media(max-width: 500px)
    padding: 26px 12px
  .visible-sm-only
    font-size: 31px
    margin: 0
    @media(max-width: 500px)
      font-size: 28px
  .text
    h1
      margin: 0
      font-size: 36px
      line-height: 40px
      font-weight: bold
      white-space: nowrap
      @media(max-width: 500px)
        font-size: 24px
        line-height: 24px
        width: 220px
    h2
      font-size: 25px
      margin: 0
      @media(max-width: 500px)
        font-size: 17px
  .logo
    width: 80px
    height: 80px
    min-width: 80px
    @media(max-width: 500px)
      width: 40px
      height: 40px
      min-width: 40px
.max-w
  max-width: 690px
  margin: auto
  padding: 0 18px
  width: 100%
  &.d-flex
    @media(max-width: 500px)
      padding: 0
</style>
